import {Injectable, signal} from "@angular/core";
import {
  BankService, BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionDetail,
  BlueBearInvoicingBusinessBankUploadBankStatementBankTransaction,
} from "../../clients";

@Injectable({providedIn: 'root'})

export class BankingService {

  constructor(private readonly bankService: BankService) {
  }


  readonly transactions$$ = signal<BlueBearInvoicingBusinessBankUploadBankStatementBankTransaction[]>([]);
  transactions$ = this.transactions$$.asReadonly();

  readonly transactionsTotalItems$$ = signal<number>(1);
  transactionsTotalItems$ = this.transactionsTotalItems$$.asReadonly();

  readonly transactionDetails$$ = signal<BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionDetail | undefined>(undefined);
  transactionDetails$ = this.transactionDetails$$.asReadonly();

  getTransactions(pageNumber: number, pageSize: number) {

    this.bankService.apiBankTransactionsGet(pageNumber, pageSize, 'response', false)
      .subscribe((data) => {
        if (data != null) {
          this.transactions$$.set(data.body!);
          let paginationInfo = JSON.parse(data.headers.get('X-Pagination')!);
          this.transactionsTotalItems$$.set(paginationInfo.TotalCount);
        }
      });
  }

  loadTransactionDetails(id: number) {
    if (id != null) {
      this.bankService.apiBankTransactionsIdGet(id).subscribe((data) => {
        this.transactionDetails$$.set(data);
      });
    }
  }
}
