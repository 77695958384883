import {Component} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader, MatExpansionPanelTitle
} from "@angular/material/expansion";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {BankingService} from "../../serivces/banking.service";
import {MatList, MatListItem} from "@angular/material/list";
import {MatLine} from "@angular/material/core";

@Component({
  selector: 'app-bank-transactions',
  standalone: true,
  imports: [
    DecimalPipe,
    MatAccordion,
    MatButton,
    MatExpansionPanel,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIcon,
    MatPaginator,
    MatList,
    MatListItem,
    MatLine
  ],
  templateUrl: './bank-transactions.component.html',
  styleUrl: './bank-transactions.component.scss'
})
export class BankTransactionsComponent {
  bankTransactions = this.bankingService.transactions$;
  totalItems = this.bankingService.transactionsTotalItems$;
  bankTransactionDetails = this.bankingService.transactionDetails$;

  constructor(private readonly bankingService: BankingService) {
    this.bankingService.getTransactions(1, 10);
  }

  pageChanged($event: PageEvent) {
    this.bankingService.getTransactions($event.pageIndex + 1, $event.pageSize);
  }

  loadTransactionDetails(id: number) {
    this.bankingService.loadTransactionDetails(id);
  }
}
