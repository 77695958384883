/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionPurchaseInvoice } from './blueBearInvoicingBusinessBankBankTransactionDetailBankTransactionPurchaseInvoice';
import { BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionSalesInvoice } from './blueBearInvoicingBusinessBankBankTransactionDetailBankTransactionSalesInvoice';
import { BlueBearInvoicingBusinessBankUploadBankStatementTransactionType } from './blueBearInvoicingBusinessBankUploadBankStatementTransactionType';

export interface BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionDetail { 
    id?: number;
    date?: string;
    counterPartyName?: string;
    counterPartyAccount?: string;
    amount?: number;
    transactionType?: BlueBearInvoicingBusinessBankUploadBankStatementTransactionType;
    statement?: string;
    account?: string;
    linked?: boolean;
    salesInvoice?: BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionSalesInvoice;
    purchaseInvoices?: Array<BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionPurchaseInvoice>;
}