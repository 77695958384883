/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlueBearInvoicingPersistanceDataModelsDataBankTransaction } from './blueBearInvoicingPersistanceDataModelsDataBankTransaction';
import { BlueBearInvoicingPersistanceDataModelsDataCustomer } from './blueBearInvoicingPersistanceDataModelsDataCustomer';
import { BlueBearInvoicingPersistanceDataModelsDataDocument } from './blueBearInvoicingPersistanceDataModelsDataDocument';
import { BlueBearInvoicingPersistanceDataModelsDataInvoiceItemLine } from './blueBearInvoicingPersistanceDataModelsDataInvoiceItemLine';

export interface BlueBearInvoicingPersistanceDataModelsDataSalesInvoice { 
    id?: number;
    customerId?: number;
    invoiceDocument?: BlueBearInvoicingPersistanceDataModelsDataDocument;
    customer?: BlueBearInvoicingPersistanceDataModelsDataCustomer;
    invoiceItemLines?: Array<BlueBearInvoicingPersistanceDataModelsDataInvoiceItemLine>;
    invoiceDate?: string;
    expriationDate?: string;
    invoiceYear?: number;
    invoiceNumber?: number;
    subTotal?: number;
    vat?: number;
    vatPercentage?: number;
    grandTotal?: number;
    bankTransactions?: Array<BlueBearInvoicingPersistanceDataModelsDataBankTransaction>;
    intraCommunity?: boolean;
}