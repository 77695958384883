import {
  BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoiceListItem,
  PurchaseInvoiceService
} from "../../clients";
import {Injectable, signal} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoicesService {


  readonly purchaseInvoices$$ = signal<BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoiceListItem[]>([]);
  purchaseInvoices$ = this.purchaseInvoices$$.asReadonly();

  readonly purchaseInvoicesTotalItems$$ = signal<number>(1);
  purchaseInvoicesTotalItems$ = this.purchaseInvoicesTotalItems$$.asReadonly();

  constructor(private readonly purchaseInvoicesApiService: PurchaseInvoiceService) {
  }

  getPurchaseInvoices(pageNumber: number, pageSize: number) {
    this.purchaseInvoicesApiService.apiPurchasesInvoicesGet(pageNumber, pageSize, 'response', false)
      .subscribe((data) => {
        if (data != null) {
          this.purchaseInvoices$$.set(data.body!);
          let paginationInfo = JSON.parse(data.headers.get('X-Pagination')!);
          this.purchaseInvoicesTotalItems$$.set(paginationInfo.TotalCount);
        }
      });
  }

  uploadNewPurchaseInvoice(file: Blob) {
    return this.purchaseInvoicesApiService.apiPurchasesInvoicesDocumentPostForm(file);
  }

  removePurchaseInvoice(purchaseInvoiceId: number) {
    return this.purchaseInvoicesApiService.apiPurchasesInvoicesInvoiceIdDelete(purchaseInvoiceId);
  }

  getPurchaseInvoicePDF(id: number) {
    this.purchaseInvoicesApiService.apiPurchasesInvoicesIdDocumentGet(id).subscribe((data: Blob) => {
      const blob = new Blob([data], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
