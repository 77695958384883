<div class="container">
  <!-- button to upload new purchase invoice -->
  <button class="upload-button" mat-raised-button color="primary" (click)="showUploadInvoice = !showUploadInvoice">
    Upload new purchase invoice
  </button>

  @if (this.showUploadInvoice) {
    <mat-card class="upload-form">
      <mat-card-content>
        <div class="dropzone" appDragAndDrop (fileDropped)="onFileChange($event)">
          <input (change)="onFileSelected($event)" type="file" multiple id="fileDropRef"/>
          <mat-icon>cloud_upload</mat-icon>
          <h3>Drag and drop files here to upload</h3>
          <h3>or</h3>
          <label for="fileDropRef">Select files</label>
          @for (file of files; track file.name) {
            <mat-list-item>
              <mat-icon matListIcon>insert_drive_file</mat-icon>
              <h4 matLine>{{ file.name }}</h4>
            </mat-list-item>
          }
        </div>
        <button mat-raised-button color="primary" (click)="uploadPurchaseInvoices()">Upload</button>
      </mat-card-content>
    </mat-card>
  }
  <div>
    <mat-accordion>
      @for (currentSalesInvoice of currentPurchasesInvoices(); track currentSalesInvoice.id) {
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              @if (currentSalesInvoice.invoiceDate) {
                {{ currentSalesInvoice.invoiceDate }} {{ currentSalesInvoice.vendorName }} (€{{ currentSalesInvoice.grandTotal | number : '1.2-2' }})
              } @else {
                Nog bezig met OCR te doen
              }
            </mat-panel-title>
            <mat-panel-description>
              @if (currentSalesInvoice.linked) {
                <mat-icon aria-hidden="false" class="green-icon" fontIcon="link"></mat-icon>
              } @else {
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="link" color="accent"></mat-icon>
              }
            </mat-panel-description>
          </mat-expansion-panel-header>
          Factuur totaal : € {{ currentSalesInvoice.grandTotal | number : '1.2-2' }} <br/>
          <button mat-raised-button color="primary" (click)="viewInvoice(currentSalesInvoice.id!)">Bekijk factuur</button>
          <button mat-raised-button color="warn" (click)="removePurchaseInvoice(currentSalesInvoice.id!)">Verwijder
            factuur
          </button>
        </mat-expansion-panel>
      }
    </mat-accordion>
    <mat-paginator (page)="pageChanged($event)"
                   [length]="totalItems()"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   aria-label="Select page">
    </mat-paginator>
  </div>
</div>
