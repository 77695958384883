/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlueBearInvoicingPersistanceDataModelsDataBankTransaction } from './blueBearInvoicingPersistanceDataModelsDataBankTransaction';
import { BlueBearInvoicingPersistanceDataModelsDataVendor } from './blueBearInvoicingPersistanceDataModelsDataVendor';
import { BlueBearInvoicingPersistanceDataModelsInvoiceSource } from './blueBearInvoicingPersistanceDataModelsInvoiceSource';

export interface BlueBearInvoicingPersistanceDataModelsDataPendingPurchaseInvoice { 
    id?: number;
    documentBlob?: string;
    fileName?: string;
    hasBeenOcr?: boolean;
    createdOn?: Date;
    ocrTime?: Date;
    invoiceDate?: string;
    vendor?: BlueBearInvoicingPersistanceDataModelsDataVendor;
    approved?: boolean;
    approvedOn?: Date;
    paid?: boolean;
    markedAsPaidOn?: Date;
    vendorId?: number;
    invoiceSource?: BlueBearInvoicingPersistanceDataModelsInvoiceSource;
    invoiceNumber?: string;
    invoiceExcludingTax?: number;
    invoiceTax?: number;
    invoiceTotal?: number;
    bankTransactions?: Array<BlueBearInvoicingPersistanceDataModelsDataBankTransaction>;
    possibleDuplicateId?: number;
}