<div class="container">
  <mat-accordion>
    @for (bankTransaction of bankTransactions(); track bankTransaction.id) {
      <mat-expansion-panel hideToggle (opened)="loadTransactionDetails(bankTransaction.id!)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ bankTransaction.date }} {{ bankTransaction.counterPartyName }}
            (€{{ bankTransaction.amount | number : '1.2-2' }})
          </mat-panel-title>
          <mat-panel-description>
            @if (bankTransaction.linked) {
              <mat-icon aria-hidden="false" class="green-icon" fontIcon="link"></mat-icon>
            } @else {
              <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="link" color="accent"></mat-icon>
            }
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="transaction-details">
          <p><strong>Amount:</strong> € {{ bankTransaction.amount | number : '1.2-2' }}</p>
          <p><strong>Counter Party:</strong> {{ bankTransaction.counterPartyName }}</p>
          <p><strong>Statement:</strong> {{ bankTransaction.statement }}</p>
          @if ((bankTransactionDetails()?.purchaseInvoices ?? []).length > 0) {
            <h4><strong>Purchase Invoices</strong></h4>
            <mat-list>
              @for (purchaseInvoice of bankTransactionDetails()?.purchaseInvoices; track purchaseInvoice.id) {
                <mat-list-item style="height: 5em;">
                  <div matLine><strong>Date:</strong> {{ purchaseInvoice.invoiceDate }}</div>
                  <div matLine><strong>Vendor:</strong> {{ purchaseInvoice.vendorName }}</div>
                  <div matLine><strong>Amount:</strong> € {{ purchaseInvoice.amount | number : '1.2-2' }}</div>
                </mat-list-item>
              }
            </mat-list>
          }
        </div>
      </mat-expansion-panel>
    }
  </mat-accordion>
  <mat-paginator (page)="pageChanged($event)"
                 [length]="totalItems()"
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 aria-label="Select page">
  </mat-paginator>
</div>
