import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./features/login/login.component";
import {unAuthorizedCanActivate} from "./guards/auth-guard.service";
import {HomeComponent} from "./features/home/home.component";
import {SalesInvoicesComponent} from "./features/sales-invoices/sales-invoices.component";
import {PurchaseInvoicesComponent} from "./features/purchase-invoices/purchase-invoices.component";
import {CreateSalesInvoiceComponent} from "./features/create-sales-invoice/create-sales-invoice.component";
import {ViewSalesInvoiceComponent} from "./features/view-sales-invoice/view-sales-invoice.component";
import {LayoutComponent} from "./shared/layout/layout.component";
import {BankTransactionsComponent} from "./features/bank-transactions/bank-transactions.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
    canActivate: [unAuthorizedCanActivate]
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: 'home', component: HomeComponent},
      {path: 'sales-invoices', component: SalesInvoicesComponent},
      {path: 'sales-invoices/:id', component: ViewSalesInvoiceComponent},
      {path: 'create-sales-invoices', component: CreateSalesInvoiceComponent},
      {path: 'purchase-invoices', component: PurchaseInvoicesComponent},
      {path: 'bank-transactions', component: BankTransactionsComponent},
      {path: '', redirectTo: '/home', pathMatch: 'full'}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
