/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type BlueBearInvoicingBusinessBankUploadBankStatementTransactionType = 0 | 1;

export const BlueBearInvoicingBusinessBankUploadBankStatementTransactionType = {
    NUMBER_0: 0 as BlueBearInvoicingBusinessBankUploadBankStatementTransactionType,
    NUMBER_1: 1 as BlueBearInvoicingBusinessBankUploadBankStatementTransactionType
};